import React from 'react';
import Footer from '../components/footer/Footer';
import HeaderCommon from '../components/header/HeaderCommon';
import Layout from '../components/layout';
import Seo from '../components/seo';
import BreadcrumbSection from '../elements/BreadcrumbSection';

const CookieStatement = () => {
  return (
    <Layout>
      <Seo
        title="Privacy Policy | banco"
        description="This Privacy Policy is used to inform visitors and users regarding our policies with regard to the collection, use, and disclosure of personally identifiable information."
      />
      <main className="main-wrapper">
        <HeaderCommon />

        <BreadcrumbSection title="Privacy Policy" paragraph="Last updated on 15 November 2021." />

        {/* Cookie Statement */}

        <Footer />
      </main>
    </Layout>
  );
};

export default CookieStatement;
